#suppliers_bg {
  margin-top: 156px;
}
#world_bg {
  height: 560px;
  background-color: #c2ee15;
  border-radius: 20px;
  font-family: Roboto;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  text-align: left;
  color: #3a440e;
  padding: 44px 32px 44px 32px;
}
#world_inner_text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #3a440e;
  margin-top: 16px;
}
#world_img {
  margin-top: 68px;
}
#smooth_bg {
  background-color: #ffa217;
  height: 268px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
#smooth_bg img {
  margin-left: 42px;
  width: 260px;
}
#smooth_text {
  font-family: Roboto;
  font-size: 48px;
  font-weight: 800;
  line-height: 56px;
  text-align: left;
  color: #6a4002;
  margin-left: 80px;
}
#smooth_subtext {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 4px;
}
#customize_bg {
  height: 268px;
  width: 100%;
  background-color: #1de1e1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding-right: 24px;
  width: 100%;
}
#customize_bg img {
  width: 196px;
}
#customize_text {
  font-family: Roboto;
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-align: left;
  color: #125252;
}
#happy_bg {
  margin-top: 24px;
  height: 268px;
}
/* tab */
@media screen and (min-width: 744px) and (max-width: 1023px) {
  #world_bg {
    height: 526px;
    margin-top: 24px;

  }
  #smooth_bg {
    background-color: #ffa217;
    height: 526px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px;
    margin-top: 24px;
  }
  #smooth_text{
    font-family: Roboto;
    font-size: 36px;
    font-weight: 800;
    line-height: 44px;
    text-align: left;    
    margin-left: 0px;
  }

  #customize_bg {
    height: 496px;
    background-color: #1de1e1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    padding: 24px;
  }
  #happy_cust_img_tab {
    height: 496px;
    width: 336px;
    margin-top: 24px;
  }
  #happy_bg {
    margin-top: 24px;
    height: auto;
  }
}
/* Large tablets and small desktops (1024px to 1200px) */
@media screen and (min-width: 1023px) and (max-width: 1200px) {
 #happy_cust_img_tab{
  width: 100%;
 }
 #customize_bg img{
  width: 140px !important;
 }
}

/* mob */
@media screen and (min-width: 320px) and (max-width: 744px) {
  #suppliers_bg {
    margin-top: 52px;
  }
  #suppliers_img_bg {
    padding: 0;
    margin: 0;
  }
  #world_bg {
    height: auto;
    background-color: #c2ee15;
    border-radius: 20px;
    font-family: Roboto;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;
    color: #3a440e;
    padding: 22px;
  }
  #world_inner_text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #3a440e;
    margin-top: 16px;
  }
  #world_img {
    margin-top: 48px;
  }
  #smooth_bg {
    background-color: #ffa217;
    height: auto;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 24px;
    padding: 24px;
  }
  #smooth_bg img {
    margin-left: 0px;
  }
  #smooth_text {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    text-align: left;
    color: #6a4002;
    margin-top: 13px;
    margin-left: 0;
  }
  #smooth_subtext {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-top: 4px;
  }
  #customize_bg {
    height: 424px;
    background-color: #1de1e1;
    border-radius: 20px;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    margin-top: 24px;
    padding-right: 0px;
    padding: 16px;
  }
  #customize_text {
    font-family: Roboto;
    font-size: 28px;
    font-weight: 800;
    line-height: 36px;
    text-align: left;
    color: #125252;
    margin-top: 16px;
  }
  #happy_bg {
    height: auto;
    margin-top: 24px;
  }
}
