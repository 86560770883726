#why_text {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-top: 120px;
}
#unprll_text {
  font-family: Roboto;
  font-size: 68px;
  font-weight: 800;
  line-height: 76px;
  text-align: center;
  margin-top: 4px;
}
#why_sub_text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 4px;
}
#why_prl_img {
  margin-top: 48px;
}
#top_qty_bg {
  min-height: 230px;
  max-height: max-content;
  padding: 32px 28px 36px 28px;
  background-color: white;
  padding-bottom: 28px;
  border: 1px solid black;
  width: 95%;
  border-radius: 20px;
  bottom: -148px;
}
#top_qty_text {
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}
#top_qty_subtext {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #494949;
  margin-top: 12px;
}
#fabric_img {
  object-fit: cover;
  background-size: cover;
  background-position: center;
}
#orders_img {
  object-fit: cover;
  background-size: cover;
  background-position: center;
}
#sustain_img {
  /* height: 640px; */
  object-fit: cover;
  background-size: cover;
  background-position: center;
}
@media screen and (min-width: 744px) and (max-width: 1024px) {
  #why_text {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-top: 80px;
  }
  #fabric_img {
    height: 640px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    margin-top: 60px;
  }

  #orders_img {
    margin-top: 60px;
    height: 640px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    border-radius: 20px;

  }

  #sustain_img {
    margin-top: 60px;
    height: 640px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    border-radius: 20px;

  }
  
}
@media screen and (min-width: 320px) and (max-width: 744px) {
  #why_text {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-top: 60px;
  }
  #unprll_text {
    font-family: Roboto;
    font-size: 44px;
    font-weight: 800;
    line-height: 52px;
    text-align: center;

    margin-top: 8px;
  }
  #why_sub_text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 8px;
  }
  #why_prl_img {
    margin-top: 28px;
  }
  #top_qty_bg {
    min-height: 164px;
    max-height: max-content;
    padding: 20px;
    background-color: white;
    border: 1px solid black;
    width: 90%;
    border-radius: 20px;
    bottom: -120px;
  }
  #orders_img {
    margin-top: 60px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
  }
  #top_qty_text {
    font-family: Roboto;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
  }
  #top_qty_subtext {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 12px;
  }
  #sustain_img {
    margin-top: 60px;
    object-fit: cover;
    background-size: cover;
    background-position: center;
  }
}
