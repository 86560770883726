#footer_bg {
  background-color: #000000;
  height: auto;
  margin-top: 120px;
  padding-bottom: 40px;
}
#footer_logo img{
  margin-top: 40px;
  width: 67px !important;
  height: 80px !important;
}
#footer_imgs {
  margin-top: 60px;
  display: flex;
  gap: 24px;
}
#footer_imgs img {
  height: 40px;
  width: 40px;
}
#footer_divider {
  width: 100%;
  height: 2px;
  background-color: #333333;
  margin-top: 32px;
}
#terms_bg {
  gap: 48px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;
  margin-top: 28px;
}
@media screen and (min-width: 320px) and (max-width: 744px) {
  #footer_bg {
    background-color: #000000;
    height: auto;
    margin-top: 120px;
  }
  #footer_logo img {
    width: 67px !important;
    height: 80px !important;
  }
  #footer_logo_bg {
    flex-direction: column;
  }
  #footer_imgs {
    margin-top: 20px;
    display: flex;
    gap: 24px;
  }
  #footer_divider {
    width: 100%;
    height: 2px;
    background-color: #333333;
    margin-top: 32px;
  }
  #terms_bg {
    gap: 28px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    flex-direction: column;
    text-align: left;
    color: #fff;
    margin-top: 28px;
  }
}
