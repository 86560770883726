#contact_bg {
  margin-top: 120px;
  background-image: url("../Assets/contact_img_bg.png");
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  height: auto;
}
#contact_text_bg {
  padding: 52px;
}
#contact_form_bg {
  padding-right: 24px;
}
iframe {
  height: 780px;
  width: 100%;
}
#contact_us {
  font-family: Roboto;
  font-size: 44px;
  font-weight: 600;
  line-height: 52px;
  text-align: left;
  color: #ffffff;
}
#reach_text {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #ffffff;
  margin-top: 20px;
}
#email_bg {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #8a2129;
  display: flex;
  align-items: center;
  justify-content: center;
}
#reach_bg {
  margin-top: 52px;
}
#reach_sing_bg {
  display: flex;
  align-items: start;
  justify-content: left;
  gap: 16px;
  margin-top: 24px;
}
#email_text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}
#email_id {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}
#contact_form {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}
label {
  margin-top: 32px;
}
#contact_form input {
  height: 56px;
  width: 100%;
  padding: 0px 16px 0px 16px;
  gap: 10px;
  border-radius: 4px;
  border: none;
  margin-top: 8px;
  background-color: white;
}
#contact_form input:focus {
  border: none;
  outline: none;
}
#contact_form textarea {
  width: 100%;
  padding: 16px;
  gap: 10px;
  border-radius: 4px;
  border: none;
  margin-top: 8px;
  height: 96px;
}
textarea:focus {
  border: none;
  outline: none;
}
#submit_btn {
  gap: 8px;
  border-radius: 4px;
  background-color: #000000;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border: none;
  outline: none;
}
#thanks_submit_btn {
  gap: 8px;
  border-radius: 4px;
  background-color: #37994c;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border: none;
  outline: none;
}
#thanks_submit_btn img {
  width: 32px;
  height: 32px;
}
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  height: 56px;
  margin-top: 8px;
  padding-left: 2px;
  background-color: white;
}

.react-international-phone-input {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.sib-form {
  background-attachment: fixed;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  padding: 32px 41px 55px 44px !important;
  margin: 0;
  background-color: yellow !important;
}
@media screen and (min-width: 744px) and (max-width: 1024px) {
  #form_bg {
    margin-top: 40px;
  }
}

@media screen and (min-width: 320px) and (max-width: 744px) {
  #contact_bg {
    margin-top: 60px;
    background-image: url("../Assets/contact_img_bg.png");
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0px 0px;
    border-radius: 20px;
    height: auto;
    padding-bottom: 20px;
  }
  #contact_text_bg {
    padding: 40px 20px 40px 28px;
  }
  #contact_form_bg {
    padding: 0px;
  }
  #contact_us {
    font-family: Roboto;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: #ffffff;
  }
  #reach_text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
    margin-top: 20px;
  }
  #email_bg {
    height: 52px;
    width: 52px;
    border-radius: 52px;
    background-color: #8a2129;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #reach_bg {
    margin-top: 28px;
  }
  #reach_sing_bg {
    display: grid;
    grid-template-columns: 15% 85%;
    grid-gap: 16px;
    align-items: start;
    margin-top: 24px;
    margin-right: 12px;
  }
  #email_text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
  }
  #email_id {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
  }
  #contact_form {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
  }
  iframe {
    display: block;
    height: 850px;
    width: 100%;
    padding-bottom: 20px;
  }
  label {
    margin-top: 32px;
  }
  #contact_form input {
    height: 56px;
    width: 100%;
    padding: 0px 16px 0px 16px;
    gap: 10px;
    border-radius: 4px;
    border: none;
    margin-top: 8px;
    background-color: white;
  }
  #contact_form input:focus {
    border: none;
    outline: none;
  }
  #contact_form textarea {
    width: 100%;
    padding: 16px;
    gap: 10px;
    border-radius: 4px;
    border: none;
    margin-top: 8px;
    height: 96px;
    background-color: white;
  }
  textarea:focus {
    border: none;
    outline: none;
  }
  #submit_btn {
    gap: 8px;
    border-radius: 4px;
    background-color: #000000;
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}
