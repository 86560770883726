#our_client_bg {
  margin-top: 160px;
  background-image: url("../Assets/our_client_bg.png");
  width: 100%;
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  padding: 0px 40px;
  border-radius: 20px;
}
#our_client_text {
  font-family: Roboto;
  font-size: 36px;
  font-weight: 600;
  line-height: 56px;
  text-align: center;
  color: #ffffff;
  padding-top: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  text-transform: uppercase;
}
#client_review_bg {
  background-color: #ffffff;
  min-height: 224px;
  max-height: max-content;
  box-shadow: 0px 4px 0px 0px #c2ee15;
  border-radius: 20px;
  border: 1px solid #000000;
  margin-top: 44px;
  padding: 20px;
  margin-bottom: 216px;
}
#client_review_text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #494949;
}
#client_bg {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}
#client_bg_img{
  margin-top: 34px;
  display: flex;
  align-items: center;
  gap: 8px;
}
#client_name {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
#client_loc {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #797979;
}
#client_bg img{
    height: 60px;
    width: 60px;
}
#client_bg_img img{
  height: 60px;
  width: 60px;
}
@media screen and (min-width: 744px) and (max-width: 1024px) {
  #our_client_text {
    margin-bottom: 44px;
  }
  #our_client_bg {
    padding-bottom: 80px;
  }
  #client_review_bg {
    margin-bottom: 12px;
    margin-top: 12px;
  }
}
@media screen and (min-width: 320px) and (max-width: 744px) {
  #our_client_bg {
    margin-top: 160px;
    background-image: url("../Assets/our_client_bg.png");
    width: 100%;
    background-size: cover; /* Ensures the image covers the entire area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    padding: 12px;
    padding-bottom: 80px;
    border-radius: 20px;
  }
  #heart_img {
    height: 28px;
    width: 28px;
  }
  #our_client_text {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-transform: uppercase;
  }
  #client_review_bg {
    background-color: #ffffff;
    height: auto;
    box-shadow: 0px 4px 0px 0px #c2ee15;
    border-radius: 20px;
    border: 1px solid #000000;
    margin-top: 44px;
    padding: 20px;
    margin-bottom: 0;
  }
  #client_review_text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #494949;
  }
  #client_bg {
    margin-top: 52px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  #client_name {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
  #client_loc {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #797979;
  }
}
