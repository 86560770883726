#collection_text {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 100px;
}
#collection_bg {
  justify-content: center;
  margin-top: 40px;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
}
#collection_bg img {
  height: 200px;
  width: 200px;
}
#tshirt_text {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
  text-transform: uppercase;
}
#types_bg {
  margin-top: 120px;
}
#mens_bg {
  width: 100%;
  object-fit: cover;
  background-size: cover;
  height: 320px;
}
#tab_types_bg img {
  margin-top: 40px;
}
/* for tab */
@media screen and (min-width: 744px) and (max-width: 1024px) {
  #collection_text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 44px;
  }
  #collection_bg {
    justify-content: center;
    margin-top: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    gap: 20px;
  }
  #collection_bg img {
    width: 100px;
    height: 100px;
  }
  #tshirt_text {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    margin-top: 12px;
    text-transform: uppercase;
  }
  #types_bg {
    margin-top: 60px;
  }
  #mens_bg {
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    height: max-content;
    margin-top: 36px;
  }
  #kids_bg {
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    height: max-content;
    margin-top: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 744px) {
  #collection_text {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 44px;
  }
  #collection_bg {
    justify-content: center;
    margin-top: 40px;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    gap: 18px;
  }
  #collection_bg img {
    width: 100px;
    height: 100px;
  }
  #tshirt_text {
    font-family: Roboto;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    margin-top: 12px;
    text-transform: uppercase;
  }
  #types_bg {
    margin-top: 60px;
  }
  #mens_bg {
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    height: max-content;
    margin-top: 36px;
  }
  #kids_bg {
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    height: max-content;
    margin-top: 20px;
  }
}
