#header_bg {
  height: 40px;
  width: 100%;
  background-color: #d11826;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
#header_text {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.55em;
  color: #ffffff;
}
#header_inner_bg {
  height: 104px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
}
#header_inner_contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#un_header_logo img {
  height: 80px;
  margin-right: 264px;
}
#header_left_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 44px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-align: center;
}
#header_contact_button {
  padding: 12px 28px 12px 28px;
  background-color: #000000;
  border-radius: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-align: center;
  width: 100%;
  color: #ffffff;
}
.offcanvas-header .btn-close{
    margin: 0 !important;
}
/*  */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  #header_bg {
    height: 40px;
    width: 100%;
    background-color: #d11826;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header_text {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.55em;
    color: #ffffff;
  }
  #header_inner_bg {
    height: 104px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    justify-content: center !important;
  }
  #header_inner_contents.container {
    margin: 0;
    padding: 0;
  }
  #header_inner_contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #un_header_logo img {
    height: 80px;
    margin-right: 30px;
    margin-left: 0px;
  }
  #header_left_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 44px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
    display: none;
  }
  #header_contact_button {
    padding: 12px 28px 12px 28px;
    background-color: #000000;
    border-radius: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
    width: 100%;

    color: #ffffff;
  }
  #header_mob_menu {
    display: flex;
    align-items: start;
    justify-content: left;
    flex-direction: column;
    gap: 20px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
  }
  #header_mob_menu div{
    padding: 0px 16px 16px 0px;
  }
  .offcanvas.offcanvas-top {
    height: 100% !important;
  }
}
#header_mob_menu {
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  padding: 16px;
  gap: 20px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-align: center;
}
#contact_btn{
  width: 100%;
}
#header_mob_menu div{
  padding: 0px 16px 16px 0px;
}
/* for tab media query */
@media screen and (min-width: 744px) and (max-width: 991px) {
  #header_bg {
    height: 40px;
    width: 100%;
    background-color: #d11826;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header_text {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.55em;
    color: #ffffff;
  }
  #header_inner_bg {
    height: 104px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    justify-content: center !important;
  }
  #header_inner_contents.container {
    margin: 0;
    padding: 0;
  }
  #header_inner_contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #un_header_logo img {
    height: 80px;
    margin-right: 0px;
    margin-left: 100px;
  }
  #header_left_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 44px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
    display: none;
  }
  #header_contact_button {
    padding: 12px 28px 12px 28px;
    background-color: #000000;
    border-radius: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
    width: 100%;

    color: #ffffff;
  }
  #header_mob_menu {
    display: flex;
    align-items: start;
    justify-content: left;
    flex-direction: column;
    gap: 20px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
  }
  #header_mob_menu div{
    padding: 0px 16px 16px 0px;
  }
  .offcanvas.offcanvas-top {
    height: 100% !important;
  }
  #header_mob_menu {
    display: flex;
    align-items: start;
    justify-content: left;
    flex-direction: column;
    padding: 16px;
    gap: 20px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
  }
  #contact_btn{
    width: 100%;
  }
  #header_mob_menu div{
    padding: 0px 16px 16px 0px;
  }
}

/* for mobile */
@media screen and (min-width: 320px) and (max-width: 744px) {
  #header_bg {
    height: 40px;
    width: 100%;
    background-color: #d11826;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header_text {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3em;
    text-align: center;
  }
  #header_inner_bg {
    height: 68px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    justify-content: center !important;
    padding: 16px;
    position: relative;
  }
  #menu_icon img {
    height: 28px;
    width: 28px;
  }
  #header_inner_contents.container {
    margin: 0;
    padding: 0;
  }
  #header_inner_contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #un_header_logo img {
    height: 52px;
    /* margin-right: 0px; */
    /* margin-left: 44px; */
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 12%;
  }
  #header_left_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 44px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
    display: none;
  }
  #button {
    padding: 12px 28px 12px 28px;
    background-color: #000000;
    border-radius: 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    width: fit-content !important;
  }
  #header_mob_menu {
    display: flex;
    align-items: start;
    justify-content: left;
    flex-direction: column;
    padding: 16px;
    gap: 20px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.08em;
    text-align: center;
  }
  #contact_btn{
    width: 100%;
  }
  #header_mob_menu div{
    padding: 0px 16px 16px 0px;
  }
  .offcanvas.offcanvas-top {
    height: 100% !important;
  }
}
