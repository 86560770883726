#partner {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  margin-top: 120px;
}
#industry {
  font-family: Roboto;
  font-size: 68px;
  font-weight: 800;
  line-height: 76px;
  text-align: center;
  margin-top: 4px;
}
#partner_subtext {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 4px;
}
.image-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  gap: 16px;
}
@media screen and (min-width: 744px) and (max-width: 1024px) {
    .image-section {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px;
        gap: 16px;
      }
}
@media screen and (min-width: 320px) and (max-width: 744px) {
  #partner {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-top: 60px;
  }
  #industry {
    font-family: Roboto;
    font-size: 44px;
    font-weight: 800;
    line-height: 52px;
    text-align: center;
    margin-top: 8px;
  }
  #partner_subtext {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 8px;
  }
  .image-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 28px;
    gap: 16px;
  }
  .image-section img{
    width: 100px;
  }
}
