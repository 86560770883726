#privacy_bg {
  background-color: #f6f6f6;
  height: auto;
  color: black;
  display: flex;
  height: 496px;
  align-items: center;
  padding: 60px;
  position: relative;
}
#privacy_img {
  position: absolute;
  bottom: 0;
  right: 0;
}
#terms_text {
  font-family: Roboto;
  font-size: 100px;
  font-weight: 800;
  line-height: 104px;
  text-align: left;
  align-items: center;
}
#terms_heading {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
}
#terms_content {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
#terms_conditions_bg {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 60px;
  margin-bottom: 60px;
}
@media screen and (min-width: 744px) and (max-width: 1200px) {
  #privacy_bg {
    background-color: #f6f6f6;
    height: auto;
    color: black;
    display: flex;
    height: 396px;
    align-items: center;
    padding: 60px;
    position: relative;
  }
  #terms_text {
    font-family: Roboto;
    font-size: 70px;
    font-weight: 800;
    line-height: 74px;
  }
}
@media screen and (min-width: 320px) and (max-width: 744px) {
  #privacy_bg {
    background-color: #f6f6f6;
    height: auto;
    color: black;
    display: flex;
    height: 296px;
    align-items: center;
    padding: 60px;
    position: relative;
  }
  #terms_text {
    font-family: Roboto;
    font-size: 50px;
    font-weight: 800;
    line-height: 54px;
  }
}